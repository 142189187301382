// Copyright © 2019 Atomic.io Limited. All rights reserved.
import React, {useEffect, useState} from 'react'

import {SettingsInput} from './components/settings-input'
import {FeatureConfiguration} from './components/configuration'
import {Dashboard} from './components/dashboard'
import {Landing} from './components/landing'

import {createStreamContainer} from './config/container'
import {Routes} from './util/routes'
import {Settings, parseMagicLink} from './util/settings'
import {clearCachedToken, getAuthToken} from './api/token'
import instanceConfig from './instances.json'
import environmentData from './environments.json'

import Bugsnag from '@bugsnag/js'
import {EmbedModes} from './components/dropdown'
import {isMobileDevice, getPlatform, getSDKVersion} from './util/helpers'
import {MobileLanding, SKIP_MOBILE_REMINDER} from './components/mobile-landing'
import {clearSDKEvents} from './util/database'
import {HeadlessContainer} from './components/headlessContainer'
import {hasFeature} from './features'

let instance
let initialised = false

const stopContainer = () => {
  if (instance) {
    instance.stop()
  }
}

const updateSettingsFromQueryParams = params => {
  const embedMode = params.get('embedMode')
  const autoresize = params.get('autoresize')

  const modes = EmbedModes.map(mode => {
    return mode.identifier
  })
  if (modes.includes(embedMode)) {
    Settings.configuration.embedMode = embedMode
  }

  if (autoresize !== null) {
    Settings.sdkFeatures.autoresizeStandaloneFrame = autoresize === 'true'
  }

  Settings.save()
}

const createContainer = async setCardCount => {
  setCardCount(0)
  const params = new URLSearchParams(window.location.search)
  if (params && instanceConfig.appType === 'alpha') {
    updateSettingsFromQueryParams(params)
  }

  await getAuthToken()

  const embedMode = Settings.configuration.embedMode
  const siteId = Settings.configuration.siteId
  let apiKey = sessionStorage.getItem('apiKey')
    ? sessionStorage.getItem('apiKey')
    : `atomic-internal:${siteId}`

  stopContainer()

  instance = createStreamContainer(
    Settings.currentApiUrl(),
    Settings.configuration.streamContainerId,
    count => {
      if (embedMode == 'launcher') {
        setCardCount(count)
      }
    },
    embedMode,
    apiKey,
    siteId,
    Settings.sdkFeatures,
    Settings.currentTestHostUrl()
  )
}

const updateVariables = () => {
  if (instance) {
    instance.updateVariables()
  }
}

let bugsnagInitialised = false
const platform = getPlatform()
const skipMobilePage = sessionStorage.getItem(SKIP_MOBILE_REMINDER) === 'true'

export const App = () => {
  const [route, changeRoute] = useState(() => {
    // Set the starting route based on path
    let startingPath = window.location.pathname
    return startingPath.length > 0 ? startingPath.substring(1) : startingPath
  })
  const [cardCount, setCardCount] = useState(0)
  const [showHeadless, setShowHeadless] = useState(
    Settings.configuration.embedMode === 'headless'
  )

  useEffect(() => {
    window.AtomicSDK.enableDebugMode(instanceConfig.debugLevel)
    window.AtomicSDK.setClientAppVersion(
      `${instanceConfig.clientAppVersionPrefix} v${process.env.npm_package_version}`
    )

    if (instanceConfig.enforceCsp) {
      const cspMeta = document.createElement('meta')
      cspMeta.setAttribute('http-equiv', 'Content-Security-Policy')
      cspMeta.setAttribute(
        'content',
        "frame-src blob:;connect-src https://*.client-api.staging.atomic.io wss://*.client-api.staging.atomic.io https://*.client-api.development.unobtainium.atomic.io wss://*.client-api.development.unobtainium.atomic.io https://*.client-api.atomic.io wss://*.client-api.atomic.io https://alpha-atomic-io-1c2d2.firebaseapp.com https://shell-atomic-io.firebaseapp.com https://demo.atomic.io wss://alpha-atomic-io-1c2d2.firebaseapp.com:* https://lumpy-quilled-line.glitch.me https://osynvf3hmqh3jtiindc5exjm5a0jtxtm.lambda-url.us-east-1.on.aws https://sessions.bugsnag.com https://edge.development.unobtainium.atomic.io/*;style-src 'self' 'unsafe-inline';font-src 'self' data: https://fonts.gstatic.com;script-src 'self' https://downloads.atomic.io;"
      )
      document.getElementsByTagName('head')[0].appendChild(cspMeta)
    }
    if (instanceConfig.retainSDKEvents) {
      clearSDKEvents()
    }
  }, [])

  if (process.env.BUGSNAG_API_KEY) {
    if (!bugsnagInitialised) {
      bugsnagInitialised = true

      // Builds performed on CI with the Bugsnag API key set.
      Bugsnag.start({
        apiKey: process.env.BUGSNAG_API_KEY,
        releaseStage: instanceConfig.appType
      })

      window.AtomicSDK.onError = error => {
        Bugsnag.notify(error)
      }
    }
  } else {
    // Local builds without Bugsnag.
    window.AtomicSDK.onError = error => {
      console.error('An error occurred in the Atomic SDK.', error)
    }
  }

  const onSettingsChanged = options => {
    changeRoute('dashboard')
    if (Settings.configuration.embedMode !== 'headless') {
      createContainer(setCardCount)
      setShowHeadless(false)
    } else {
      setShowHeadless(true)
      stopContainer()
      setCardCount(0)
    }
    if (options && options.reload) {
      window.location.reload()
    }
  }

  const onConfigureSDKFeatures = () => {
    changeRoute(Routes.features)
  }

  const onLaunch = () => {
    // Create the SDK only when first mounted.
    if (initialised) {
      return
    }

    if (Settings.configuration.embedMode !== 'headless') {
      createContainer(setCardCount)
    }
    initialised = true
  }

  switch (route) {
    case Routes.configure:
      if (
        instanceConfig.mobileLandingPage &&
        !skipMobilePage &&
        isMobileDevice() &&
        platform !== 'Unknown'
      ) {
        // Present user with CTA to install mobile app
        return <MobileLanding />
      }

      // The user is configuring the app using a magic link.
      // Save the configuration and redirect them back to the dashboard.
      const link = parseMagicLink(window.location)

      if (link) {
        Settings.configuration.organisationId = link.organisationId
        Settings.configuration.siteId = link.siteId
        Settings.configuration.streamContainerId = link.streamContainerId
        Settings.configuration.authSecret = link.authSecret

        if (link.apiEnvironment) {
          // Check the environment exists.
          const environments = environmentData.environments.filter(
            e => e.identifier === link.apiEnvironment
          )

          if (environments.length > 0) {
            Settings.configuration.environmentId = link.apiEnvironment
          }
        }

        if (link.customTokenUrl) {
          Settings.configuration.customTokenUrl = link.customTokenUrl
        }

        Settings.save()

        clearCachedToken()
      }

      window.location = '/'
      return null
    case Routes.settings:
      // The user is configuring the app after first run.
      return (
        <SettingsInput
          onCancel={() => changeRoute(null)}
          onSettingsChanged={onSettingsChanged}
        />
      )
    case Routes.features:
      return instanceConfig.appType === 'demo' ? null : (
        <FeatureConfiguration
          onCancel={() => {
            changeRoute(null)
          }}
          onSettingsChanged={onSettingsChanged}
        />
      )
    default:
      if (Settings.hasCompleteConfiguration()) {
        // Display the dashboard with the stream container on it.
        return (
          <>
            <Dashboard
              changeRoute={changeRoute}
              cardCount={cardCount}
              onLaunch={onLaunch}
              onSettingChanged={onSettingsChanged}
              onRefreshRuntimeVariables={updateVariables}
              onConfigureSDKFeatures={onConfigureSDKFeatures}
              onLogout={() => {
                stopContainer()
                changeRoute('/')
              }}
            />
            {hasFeature('headlessStreamContainer', getSDKVersion()) && (
              <HeadlessContainer displayContainer={showHeadless} />
            )}
          </>
        )
      } else {
        // The user has never configured the app.
        return (
          <Landing
            onMagicLink={() => {
              window.location = `${
                Settings.currentEnvironment().workbenchUrl
              }/demo-login.html?app=${instanceConfig.appType}`
            }}
            onManualEntry={() => {
              changeRoute(Routes.settings)
            }}
          />
        )
      }
  }
}
