// Copyright © 2019 Atomic.io Limited. All rights reserved.
import React from 'react'
import ReactDOM from 'react-dom'

import 'babel-polyfill'

// Required for IE 11
import 'whatwg-fetch'
import 'url-search-params-polyfill'

import 'normalize.css'
import './style.css'

import instance from './instances.json'
import {App} from './app'
import {Settings} from './util/settings'
import {getSDKVersion} from './util/helpers'

Settings.load()
// Re-save the settings to ensure any that have been disabled by the SDK version selected
// are updated in local storage
Settings.save()

if (
  window.location &&
  window.location.search &&
  window.location.search.length !== 0
) {
  const params = new URLSearchParams(window.location.search)
  const sdkVersion = params.get('sdkVersion')
  sdkVersion !== null && sessionStorage.setItem('sdkVersion', sdkVersion)
}
const sdkVersion = getSDKVersion()

if (instance.identifier !== 'beta') {
  //TODO: remove this after SDK 23.4.0 theme testing is completed, prevents caching of theme
  window.indexedDB.deleteDatabase('AtomicSDK')
}

// We have to wait for the Atomic SDK script to load, otherwise we will
// not have a reference to `AtomicSDK` on window.
const sdkScript = document.createElement('script')
sdkScript.setAttribute('src', Settings.sdkUrl(sdkVersion))
sdkScript.onload = () => {
  ReactDOM.render(<App />, document.getElementById('root'))
}
sdkScript.onerror = () => {
  console.error('The Atomic SDK failed to load. Please refresh and try again.')
}
document.body.appendChild(sdkScript)
