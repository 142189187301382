// Copyright © 2021 Atomic.io Limited. All rights reserved.
import React, {useState} from 'react'
import {Settings} from '../util/settings'
import {SettingsIcon, Check} from '../components/icons'
import {getExcludedFeaturesList} from '../features'
import {getSDKVersion} from '../util/helpers'

const ConfigurationOptions = [
  {
    key: 'autoresizeStandaloneFrame',
    title: 'Autoresizes standalone embed',
    type: 'boolean'
  },
  {
    key: 'cardListToastEnabled',
    title: 'Card list toast messages',
    type: 'boolean'
  },
  {
    title: 'Toast message configuration:',
    key: 'toastConfig',
    items: [
      {
        title: 'Display timeout (ms)',
        key: 'timeout',
        type: 'number'
      },
      {
        title: 'Custom submit message',
        key: 'submittedCard',
        type: 'text'
      },
      {
        title: 'Custom dismiss message',
        key: 'dismissedCard',
        type: 'text'
      },
      {
        title: 'Custom snooze message',
        key: 'snoozedCard',
        type: 'text'
      },
      {
        title: 'Custom feedback message',
        key: 'feedbackReceived',
        type: 'text'
      }
    ],
    type: 'group'
  },
  {
    key: 'cardMinimumHeight',
    title: 'Card minimum height',
    type: 'number'
  },
  {
    key: 'interfaceStyle',
    title: 'Interface style',
    options: [
      {
        key: 'automatic',
        value: 'Follow system setting',
        defaultOption: true
      },
      {
        key: 'light',
        value: 'Always use light theme'
      },
      {
        key: 'dark',
        value: 'Always use dark theme'
      }
    ],
    type: 'list'
  },
  {
    title: 'Request user metrics',
    key: 'userMetrics',
    type: 'button'
  },
  {
    title: 'Request one-off card count',
    key: 'cardCount',
    type: 'button'
  },
  {
    title: 'Custom event:',
    key: 'customEvent',
    items: [
      {
        title: 'Event name',
        key: 'eventName',
        type: 'text'
      },
      {
        title: 'Event properties JSON',
        key: 'properties',
        type: 'text'
      },
      {
        title: 'Send custom event',
        key: 'sendCustomEvent',
        type: 'submit'
      }
    ],
    type: 'form'
  },
  {
    title: 'Disable WebSockets',
    key: 'webSocketDisabled',
    type: 'boolean'
  },
  {
    title: 'Show card list header',
    key: 'cardListHeader',
    type: 'boolean'
  },
  {
    title: 'Display custom container header',
    key: 'customContainerHeader',
    type: 'boolean'
  },
  {
    title: 'Simulate initial token error',
    key: 'simulateTokenError',
    type: 'boolean'
  },
  {
    title: 'Runtime variable analytics enabled',
    key: 'runtimeVariableAnalytics',
    type: 'boolean'
  },
  {
    title: 'Observe SDK events',
    key: 'observeSDKEvents',
    type: 'boolean',
    featureKey: 'sdkEventObserver'
  },
  {
    title: 'Fetch FA icons remotely',
    key: 'fetchIconsRemotely',
    type: 'boolean',
    featureKey: 'fetchIconsRemotely'
  },
  {
    title: 'Customise launcher button',
    key: 'customiseLauncherButton',
    options: [
      {
        key: '{}',
        value: 'Standard',
        defaultOption: true
      },
      {
        key:
          '{"backgroundColor": "#7fffd4", "openIconSrc": "custom", "closeIconSrc": "custom"}',
        value: 'Blue'
      },
      {
        key:
          '{"backgroundColor": "#32cd32", "openIconSrc": "custom", "closeIconSrc": "custom"}',
        value: 'Green'
      },
      {
        key: '{"disabled": true}',
        value: 'Disabled'
      }
    ],
    type: 'list'
  },
  {
    title: 'Horizontal stream container configuration:',
    key: 'horizontalContainerConfig',
    items: [
      {
        title: 'Card width',
        key: 'cardWidth',
        type: 'number'
      },
      {
        title: 'Empty style',
        key: 'emptyStyle',
        options: [
          {
            key: 'standard',
            value: 'Display "Up to date" UI',
            defaultOption: true
          },
          {
            key: 'shrink',
            value: 'Shrink container when empty'
          }
        ],
        type: 'list'
      },
      {
        title: 'Header alignment',
        key: 'headerAlignment',
        options: [
          {
            key: 'center',
            value: 'Centre aligned',
            defaultOption: true
          },
          {
            key: 'left',
            value: 'Left aligned'
          }
        ],
        type: 'list'
      },
      {
        title: 'Scroll mode',
        key: 'scrollMode',
        options: [
          {
            key: 'snap',
            value: 'Snap between items',
            defaultOption: true
          },
          {
            key: 'free',
            value: 'Free scroll'
          }
        ],
        type: 'list'
      },
      {
        title: 'Last card alignment',
        key: 'lastCardAlignment',
        options: [
          {
            key: 'left',
            value: 'Left aligned',
            defaultOption: true
          },
          {
            key: 'center',
            value: 'Centre aligned'
          }
        ],
        type: 'list'
      }
    ],
    type: 'group'
  }
]

const EnabledConfigOptions = ConfigurationOptions.filter(option => {
  if (option.featureKey === undefined) {
    return true
  }
  return !getExcludedFeaturesList(getSDKVersion()).includes(option.featureKey)
})

const InputField = ({
  title,
  type,
  valueChanged,
  identifier,
  value,
  options
}) => {
  const [inputValue, setInputValue] = useState(value)

  switch (type) {
    case 'boolean':
      return (
        <label className="checkbox-field" htmlFor={identifier}>
          <input
            type="checkbox"
            name={identifier}
            id={identifier}
            onChange={ev => {
              setInputValue(ev.target.checked)
              valueChanged(ev.target.checked)
            }}
            checked={inputValue}
          ></input>
          <span className="checkbox-icon">
            <Check />
          </span>
          {title}
        </label>
      )
    case 'number':
      return (
        <label className="input-field" htmlFor={identifier}>
          <input
            placeholder=" "
            type="number"
            name={identifier}
            onChange={ev => {
              valueChanged(ev.target.value)
              setInputValue(ev.target.value)
            }}
            value={inputValue}
          />
          <div className="fake-label">{title}</div>
        </label>
      )
    case 'text':
      return (
        <label className="input-field" htmlFor={identifier}>
          <input
            placeholder=" "
            type="text"
            name={identifier}
            onChange={ev => {
              valueChanged(ev.target.value)
              setInputValue(ev.target.value)
            }}
            value={inputValue}
          />
          <div className="fake-label">{title}</div>
        </label>
      )
    case 'list':
      let defaultOption = ''
      const defaultOptions = options.filter(opt => opt.defaultOption)

      if (defaultOptions.length) {
        defaultOption = defaultOptions[0].key
      }

      return (
        <label className="list-field" htmlFor={identifier}>
          <div className="fake-label">{title}</div>
          <select
            name={identifier}
            defaultValue={inputValue || defaultOption}
            onChange={ev => {
              valueChanged(ev.target.value)
              setInputValue(ev.target.value)
            }}
          >
            {options.map(opt => {
              return (
                <option key={opt.key} name={opt.key} value={opt.key}>
                  {opt.value}
                </option>
              )
            })}
          </select>
        </label>
      )
    case 'button':
      return (
        <button
          className="button-dark button-dark-spaced"
          onClick={() => {
            performAction(identifier)
          }}
        >
          {title}
        </button>
      )
  }
}

/**
 * Screen used to configure SDK features.
 */
const FeatureConfiguration = ({onCancel, onSettingsChanged}) => {
  const sdkFeatures = Settings.sdkFeatures

  const initialConfig = {...sdkFeatures}
  const [featureConfig, setFeatureConfig] = useState(initialConfig)

  return (
    <div className="settings-wrapper">
      <div className="layout-margin"></div>
      <div className="settings-inner">
        <SettingsIcon />

        <h1>SDK features</h1>

        <p>Enable or disable SDK specific features.</p>

        <div className="settings-form">
          {EnabledConfigOptions.map(option => {
            if (option.type === 'group') {
              return (
                <div className="settings-group-wrapper" key={option.key}>
                  <p className="settings-group-title">{option.title}</p>
                  {option.items.map(item => {
                    const groupConfig = featureConfig[option.key]
                    return (
                      <InputField
                        title={item.title}
                        value={groupConfig[item.key]}
                        identifier={item.key}
                        type={item.type}
                        options={item.options}
                        valueChanged={value => {
                          var modifiedValue = undefined

                          switch (item.type) {
                            case 'number':
                              let parsedInt = parseInt(value)

                              if (!isNaN(parsedInt)) {
                                modifiedValue = parsedInt
                              }
                              break
                            case 'text':
                              if (value === '') {
                                delete groupConfig[item.key]

                                return setFeatureConfig({
                                  ...featureConfig,
                                  [option.key]: groupConfig
                                })
                              }
                              modifiedValue = value
                              break
                            case 'boolean':
                            case 'list':
                              modifiedValue = value
                              break
                          }

                          if (modifiedValue !== undefined) {
                            groupConfig[item.key] = modifiedValue
                            setFeatureConfig({
                              ...featureConfig,
                              [option.key]: groupConfig
                            })
                          }
                        }}
                        key={`${option.key}${item.key}`}
                      />
                    )
                  })}
                </div>
              )
            }

            if (option.type === 'form') {
              const [formState, setFormState] = useState({})

              return (
                <div className="settings-group-wrapper" key={option.key}>
                  <p className="settings-group-title">{option.title}</p>
                  {option.items.map(item => {
                    if (item.type === 'submit') {
                      return (
                        <button
                          className="button-dark button-dark-spaced"
                          onClick={() => {
                            performAction(item.key, formState)
                          }}
                        >
                          {item.title}
                        </button>
                      )
                    }

                    return (
                      <InputField
                        title={item.title}
                        value={formState[item.key]}
                        identifier={item.key}
                        type={item.type}
                        options={item.options}
                        valueChanged={value => {
                          var modifiedValue = undefined

                          switch (item.type) {
                            case 'number':
                              let parsedInt = parseInt(value)

                              if (!isNaN(parsedInt)) {
                                modifiedValue = parsedInt
                              }
                              break
                            case 'text':
                            case 'boolean':
                            case 'list':
                              modifiedValue = value
                              break
                          }

                          setFormState(state => ({
                            ...state,
                            [item.key]: modifiedValue
                          }))
                        }}
                        key={`${option.key}${item.key}`}
                      />
                    )
                  })}
                </div>
              )
            }

            return (
              <InputField
                title={option.title}
                value={featureConfig[option.key]}
                identifier={option.key}
                type={option.type}
                options={option.options}
                valueChanged={value => {
                  var modifiedValue = undefined

                  switch (option.type) {
                    case 'number':
                      let parsedInt = parseInt(value)

                      if (!isNaN(parsedInt)) {
                        modifiedValue = parsedInt
                      }
                      break
                    case 'boolean':
                    case 'list':
                      modifiedValue = value
                      break
                  }

                  if (modifiedValue !== undefined) {
                    setFeatureConfig({
                      ...featureConfig,
                      [option.key]: modifiedValue
                    })
                  }
                }}
                key={option.key}
              />
            )
          })}
          <button
            className="button-dark"
            data-testId="sdk-settings-save"
            onClick={() => {
              Settings.sdkFeatures = featureConfig
              Settings.save()

              onSettingsChanged({
                reload:
                  initialConfig.fetchIconsRemotely !==
                  featureConfig.fetchIconsRemotely
              })
            }}
          >
            Save
          </button>

          <button onClick={onCancel} className="button-light">
            Cancel
          </button>
        </div>
      </div>
      <div className="layout-margin"></div>
    </div>
  )
}

const performAction = async (identifier, payload) => {
  const containerId = Settings.configuration.streamContainerId

  switch (identifier) {
    case 'userMetrics':
      try {
        const metrics = await AtomicSDK.requestUserMetrics()
        const lines = [
          `Total cards: ${metrics.totalCards()}`,
          `Unseen cards: ${metrics.unseenCards()}\n`,
          `Total cards in container ${containerId}: ${metrics.totalCardsForStreamContainer(
            containerId
          )}`,
          `Unseen cards in container ${containerId}: ${metrics.unseenCardsForStreamContainer(
            containerId
          )}`
        ]
        window.alert(
          `User metrics successfully retrieved.\n\n${lines.join('\n')}`
        )
      } catch (e) {
        window.alert(`Failed to retrieve user metrics. ${e}`)
      }
      break
    case 'cardCount':
      try {
        const result = await AtomicSDK.requestCardCount(containerId)
        window.alert(
          `The one-off card count for the active stream container (${containerId}) is ${result.totalCards}.`
        )
      } catch (e) {
        window.alert(`Failed to retrieve one-off card count. ${e}`)
      }
      break
    case 'sendCustomEvent':
      const event = {
        ...payload,
        properties: JSON.parse(payload.properties || '{}')
      }
      await AtomicSDK.sendCustomEvent(event).catch(error => {
        window.alert(`error sending custom event: ${error}`)
      })
      break
  }
}

export {FeatureConfiguration}
