import React, {useState, useEffect} from 'react'
import {createStreamContainer} from '../config/container'
import {Settings} from '../util/settings'

export const HeadlessContainer = ({displayContainer}) => {
  const [instance, setInstance] = useState()
  const [cards, setCards] = useState([])
  const [runtimeVariableAnalytics, setRuntimeVariableAnalytics] = useState(
    false
  )

  useEffect(() => {
    if (!displayContainer) {
      stop()
    } else {
      createContainer()
    }
  }, [displayContainer])

  const cardFeedHandler = cards => {
    console.log('Headless container received cards: ', cards)
    setCards(cards)
  }

  if (!displayContainer) {
    return null
  }

  function createContainer(runtimeVariableAnalytics) {
    const container = createStreamContainer(
      Settings.currentApiUrl(),
      Settings.configuration.streamContainerId,
      undefined,
      'headless',
      sessionStorage.getItem('apiKey')
        ? sessionStorage.getItem('apiKey')
        : `atomic-internal:${Settings.configuration.siteId}`,
      Settings.configuration.siteId,
      {...Settings.sdkFeatures, runtimeVariableAnalytics},
      Settings.currentTestHostUrl(),
      cardFeedHandler
    )
    setInstance(container)
    setRuntimeVariableAnalytics(
      container.store.getState().cardFeed.runtimeVariableAnalyticsEnabled
    )
  }

  function start() {
    instance && instance.start()
  }

  function stop() {
    instance && instance.stop()
    setCards([])
  }

  function refreshRuntimeVariables() {
    instance && instance.updateVariables()
  }

  async function dismiss(cardId) {
    if (instance && window.AtomicSDK) {
      await window.AtomicSDK.onCardAction(
        instance.resolvedConfiguration.streamContainerId,
        cardId
      ).dismiss(
        () => {
          console.log('successfully dismissed card')
        },
        error => {
          console.error('failed to dismiss card', error)
        }
      )
    }
  }

  async function snooze(cardId, interval) {
    if (instance && window.AtomicSDK) {
      await window.AtomicSDK.onCardAction(
        instance.resolvedConfiguration.streamContainerId,
        cardId
      ).snooze(
        () => {
          console.log('successfully snoozed card')
        },
        error => {
          console.error('failed to snooze card', error)
        },
        interval
      )
    }
  }

  async function submit(cardId, response) {
    if (instance && window.AtomicSDK) {
      await window.AtomicSDK.onCardAction(
        instance.resolvedConfiguration.streamContainerId,
        cardId
      ).submit(
        () => {
          console.log('successfully submitted card')
        },
        error => {
          console.error('failed to submit card', error)
        },
        response
      )
    }
  }

  function filterHighPriorityCards() {
    if (instance) {
      instance.streamFilters.addCardPriorityFilter().greaterThan(5)
      instance.streamFilters.apply()
    }
  }

  function clearCardFilter() {
    if (instance) {
      instance.streamFilters.clearFilters()
    }
  }

  const renderCard = card => {
    return (
      <div className="headless-card" key={card.instance.id}>
        <p>id: {card.instance.id}</p>
        {card.defaultView.nodes.map((node, i) => {
          if (node && node.attributes && node.attributes.text) {
            return <p key={i}>{node.attributes.text}</p>
          }
        })}
        <textarea
          id={`${card.instance.id}-payload`}
          style={{display: 'block'}}
          placeholder="JSON payload"
        ></textarea>
        <button onClick={() => dismiss(card.instance.id)}>dismiss</button>
        <button onClick={() => snooze(card.instance.id, 10)}>snooze 10s</button>
        <button
          onClick={() => {
            let payload = {}
            try {
              payload = JSON.parse(
                document.getElementById(`${card.instance.id}-payload`).value
              )
            } catch (e) {
              console.error('Invalid JSON payload', e)
            }
            submit(card.instance.id, payload)
          }}
        >
          submit (with payload)
        </button>
        <button onClick={() => submit(card.instance.id)}>
          submit (empty payload)
        </button>
      </div>
    )
  }

  return (
    <div className="headless-wrapper">
      <h1>Headless Container</h1>
      <div className="headless-buttons">
        <button className="button-dark button-small" onClick={start}>
          Start updates
        </button>
        <button
          className="button-light button-bordered button-small"
          onClick={stop}
        >
          Stop updates
        </button>
        <button
          className="button-light button-bordered button-small"
          onClick={refreshRuntimeVariables}
        >
          Refresh runtime vars
        </button>
        <button
          className="button-light button-bordered button-small"
          onClick={() => {
            setRuntimeVariableAnalytics(prev => {
              createContainer(!prev)
              return !prev
            })
          }}
        >
          {`Toggle runtime variable analytics ${
            runtimeVariableAnalytics ? 'off' : 'on'
          }`}
        </button>
      </div>
      <div className="headless-buttons">
        <button
          className="button-light button-bordered button-small"
          onClick={filterHighPriorityCards}
        >
          Apply card filter ( priority &gt; 5 )
        </button>
        <button
          className="button-light button-bordered button-small"
          onClick={clearCardFilter}
        >
          Clear card filter
        </button>
      </div>
      <p>Card list:</p>
      <div className="headless-cards">{cards.map(renderCard)}</div>
    </div>
  )
}
