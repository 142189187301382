// Copyright © 2019 Atomic.io Limited. All rights reserved.
import React, {useState, useEffect, useRef} from 'react'
import {Settings} from '../util/settings'
import {Routes} from '../util/routes'
import instance from '../instances.json'
import environmentData from '../environments.json'
import {clearCachedToken} from '../api/token'
import {createStreamContainer} from '../config/container'
import {getSDKVersion} from '../util/helpers'
import {
  AlphaAppSDKVersions,
  ConnectAppSDKVersions,
  ShellAppSDKVersions,
  hasFeature
} from '../features'

export const EmbedModes = [
  {
    identifier: 'standalone',
    title: 'Standalone'
  },
  {
    identifier: 'standalone-horizontal',
    title: 'Standalone (horizontal)'
  },
  {
    identifier: 'launcher',
    title: 'Launcher'
  },
  {
    identifier: 'single-card',
    title: 'Single card'
  }
]

if (
  instance.identifier !== 'beta' &&
  hasFeature('headlessStreamContainer', getSDKVersion())
) {
  EmbedModes.push({
    identifier: 'headless',
    title: 'Headless'
  })
}

const DropdownMenu = ({
  changeRoute,
  onSettingChanged,
  onLogout,
  onClose,
  onRefreshRuntimeVariables,
  onConfigureSDKFeatures
}) => {
  const keepOpen = e => {
    e.preventDefault()
    e.stopPropagation()
  }

  const dropdownRef = useRef()

  const listener = event => {
    if (dropdownRef.current && dropdownRef.current.contains(event.target)) {
      return
    }

    onClose(event)
  }

  // Close dropdown menu when user clicks outside.
  useEffect(() => {
    document.body.addEventListener('click', listener)

    return () => {
      document.body.removeEventListener('click', listener)
    }
  }, [])

  return (
    <div className="dropdown" onClick={keepOpen}>
      <div className="dropdown-inner" ref={dropdownRef}>
        {instance.environmentSelector && (
          <EnvironmentSelector
            environmentChanged={env => {
              onSettingChanged()
            }}
          />
        )}
        {instance.sdkVersionSelector && <SDKSelector />}

        <EmbedModeSelector
          embedModeChanged={() => {
            onSettingChanged()
          }}
        />

        <DropdownWrapper className="grouped">
          <SettingsButton
            title="Refresh runtime variables"
            className="button-light button-bordered button-small"
            onClick={event => {
              onRefreshRuntimeVariables()
              onClose(event)
            }}
          />
          <SettingsButton
            title="Trigger polling fallback"
            className="button-light button-bordered button-small"
            onClick={event => {
              try {
                AtomicSDK.triggerPollingFallback('simulated')
              } catch (e) {
                window.alert(`Failed to trigger polling fallback. ${e}`)
              } finally {
                onClose(event)
              }
            }}
          />
          <SettingsButton
            title={`Toggle toast message display ${
              Settings.sdkFeatures.cardListToastEnabled === false ? 'on' : 'off'
            }`}
            className="button-light button-bordered button-small"
            onClick={event => {
              Settings.sdkFeatures.cardListToastEnabled = !Settings.sdkFeatures
                .cardListToastEnabled
              Settings.save()
              onSettingChanged()
              onClose(event)
            }}
          />
          {instance.appType !== 'demo' && (
            <SettingsButton
              title="Trigger SDK log out"
              className="button-light button-bordered button-small"
              onClick={event => {
                try {
                  AtomicSDK.logout()
                } catch (e) {
                  window.alert(`Failed to trigger SDK log out. ${e}`)
                } finally {
                  onClose(event)
                }
              }}
            />
          )}
          {instance.appType !== 'demo' && (
            <SettingsButton
              title="Log in to SDK"
              className="button-light button-bordered button-small"
              onClick={event => {
                try {
                  const apiHost = Settings.currentApiUrl()
                  const siteId = Settings.configuration.siteId
                  let apiKey = sessionStorage.getItem('apiKey')
                    ? sessionStorage.getItem('apiKey')
                    : `atomic-internal:${siteId}`

                  createStreamContainer(
                    apiHost,
                    Settings.configuration.streamContainerId,
                    () => null,
                    Settings.configuration.embedMode,
                    apiKey,
                    siteId,
                    Settings.sdkFeatures,
                    Settings.currentTestHostUrl()
                  )
                } catch (e) {
                  window.alert(`Failed to log in. ${e}`)
                } finally {
                  onClose(event)
                }
              }}
            />
          )}
          {instance.appType !== 'demo' && (
            <SettingsButton
              title="SDK features"
              className="button-light button-bordered button-small"
              onClick={event => {
                onConfigureSDKFeatures()
                onClose(event)
              }}
            />
          )}
        </DropdownWrapper>

        <DropdownWrapper className="grouped">
          <DropdownTitle title="Settings" />
          <SettingsRow
            title="Organisation ID"
            value={Settings.configuration.organisationId}
            valueClass="monospace"
          />
          <SettingsRow
            title="Environment ID"
            value={Settings.configuration.siteId}
            valueClass="monospace"
          />
          <SettingsRow
            title="Container ID"
            value={Settings.configuration.streamContainerId}
          />
          {instance.authSecret && (
            <SettingsRow
              title="Auth secret"
              value={Settings.configuration.authSecret}
              valueClass="monospace"
            />
          )}
          <SettingsRow
            title="Background URL"
            value={Settings.configuration.backgroundUrl}
            valueClass=""
          />
          {instance.customTokenUrl && (
            <SettingsRow
              title="Custom token url"
              value={Settings.configuration.customTokenUrl}
              valueClass="monospace"
            />
          )}
          <SettingsButton
            title="Edit settings"
            className="button-light button-bordered button-small"
            onClick={() => {
              changeRoute(Routes.settings)
            }}
          />
        </DropdownWrapper>
        <DropdownWrapper className="grouped">
          <SettingsButton
            title="Log out"
            className="button-dark button-small"
            onClick={() => {
              Settings.reset()

              onLogout()
              onClose(event)
            }}
          />
        </DropdownWrapper>
      </div>
    </div>
  )
}

const DropdownTitle = ({title}) => <h2 className="dropdown-title">{title}</h2>

const DropdownSubtitle = ({subtitle}) => (
  <p className="dropdown-subtitle">{subtitle}</p>
)

const DropdownAction = ({title, onClick}) => (
  <p className="dropdown-action">
    <button {...{onClick}} className="button-light">
      {title}
    </button>
  </p>
)

const DropdownWrapper = ({children, className}) => (
  <div className={'dropdown-item ' + className}>{children}</div>
)

const DropdownSelectorIcon = ({open}) => {
  if (open) {
    return (
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g opacity="0.5">
          <path
            d="M15 12L10 7L5 12"
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </svg>
    )
  } else {
    return (
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g opacity="0.5">
          <path
            d="M5 8L10 13L15 8"
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </svg>
    )
  }
}

const Selector = ({
  title,
  selectedValue,
  items,
  onClick,
  isSelected,
  getItemTitle,
  getItemKey
}) => {
  const [open, setOpen] = useState(false)

  const toggleSelector = () => {
    setOpen(!open)
  }

  return (
    <DropdownWrapper className={'selector horizontal' + (open ? ' open' : '')}>
      <a onClick={toggleSelector} className="selector-toggle">
        <div className="selector-title">{title}</div>
        <div className={'selector-value' + (open ? ' open' : '')}>
          {selectedValue()}
        </div>
        <DropdownSelectorIcon open={open} />
      </a>
      {open && (
        <ul>
          {items.map(item => {
            const selected = isSelected(item)

            return (
              <li key={getItemKey(item)} className={selected ? 'selected' : ''}>
                <a
                  onClick={() => {
                    onClick(item)
                    setOpen(false)
                  }}
                  className={selected ? 'open' : ''}
                >
                  {getItemTitle(item)}
                </a>
              </li>
            )
          })}
        </ul>
      )}
    </DropdownWrapper>
  )
}

const EnvironmentSelector = ({environmentChanged}) => {
  return (
    <Selector
      title="Server"
      selectedValue={() => {
        return (
          Settings.currentEnvironment() && Settings.currentEnvironment().name
        )
      }}
      items={environmentData.environments}
      onClick={env => {
        Settings.configuration.environmentId = env.identifier
        Settings.save()

        clearCachedToken()
        environmentChanged()
      }}
      isSelected={env => {
        return env.identifier === Settings.selectedEnvironmentId()
      }}
      getItemTitle={env => {
        return env.name
      }}
      getItemKey={env => {
        return env.identifier
      }}
    />
  )
}

let availableSDKVersions = AlphaAppSDKVersions
if (instance.identifier === 'shell') {
  availableSDKVersions = ShellAppSDKVersions
} else if (instance.identifier === 'beta') {
  availableSDKVersions = ConnectAppSDKVersions
}
const SDKSelector = () => (
  <Selector
    title="SDK version"
    selectedValue={() => getSDKVersion()}
    items={availableSDKVersions}
    onClick={version => {
      sessionStorage.setItem('sdkVersion', version.identifier)
      window.location.reload()
    }}
    isSelected={version => version.identifier === getSDKVersion()}
    getItemTitle={version => version.name}
    getItemKey={version => version.identifier}
  />
)

const EmbedModeSelector = ({embedModeChanged}) => {
  return (
    <Selector
      title="Variant"
      selectedValue={() => {
        return EmbedModes.filter(
          mode => mode.identifier === Settings.configuration.embedMode
        )[0].title
      }}
      items={EmbedModes}
      onClick={mode => {
        Settings.configuration.embedMode = mode.identifier
        Settings.save()
        embedModeChanged()
      }}
      isSelected={mode => {
        return mode.identifier === Settings.configuration.embedMode
      }}
      getItemTitle={mode => {
        return mode.title
      }}
      getItemKey={mode => {
        return mode.identifier
      }}
    />
  )
}

const SettingsRow = ({title, value, valueClass}) => {
  return (
    <div className="dropdown-item-row">
      <div className="selector-title">{title}</div>
      <div className={'selector-value ' + valueClass}>{value || 'not set'}</div>
    </div>
  )
}

const SettingsButton = ({title, className, onClick}) => {
  return (
    <div className="dropdown-item-row">
      <button className={className} onClick={onClick} data-testId={title}>
        {title}
      </button>
    </div>
  )
}

export {DropdownMenu}
