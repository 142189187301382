import instance from '../instances.json'

export const isMobileDevice = () => {
  /**
   * https://developer.mozilla.org/en-US/docs/Web/HTTP/Browser_detection_using_the_user_agent#mobile_device_detection
   */

  let hasTouchScreen = false
  if ('maxTouchPoints' in navigator) {
    hasTouchScreen = navigator.maxTouchPoints > 0
  } else if ('msMaxTouchPoints' in navigator) {
    hasTouchScreen = navigator.msMaxTouchPoints > 0
  } else {
    const mQ = matchMedia ? matchMedia('(pointer:coarse)') : undefined
    if ((mQ || {}).media === '(pointer:coarse)') {
      hasTouchScreen = !!mQ.matches
    } else if ('orientation' in window) {
      hasTouchScreen = true // deprecated, but good fallback
    } else {
      // Only as a last resort, fall back to user agent sniffing
      const UA = navigator.userAgent
      hasTouchScreen =
        /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(UA) ||
        /\b(Android|Windows Phone|iPad|iPod)\b/i.test(UA)
    }
  }

  return hasTouchScreen
}

export const getPlatform = () => {
  if (navigator.userAgent.match(/android/i)) {
    return 'Android'
  }
  if (navigator.userAgent.match(/ios|iphone|ipad/i)) {
    return 'iOS'
  }
  return 'Unknown'
}

export const getSDKVersion = () => {
  const version = sessionStorage.getItem('sdkVersion')
  return version ? version : instance.sdkVersion
}
