// Copyright © 2019 Atomic.io Limited. All rights reserved.
const CONFIGURATION_KEY = 'ATOMIC_CONFIG'
const FEATURE_CONFIGURATION_KEY = 'ATOMIC_FEATURE_CONFIG'

import environmentData from '../environments.json'
import instance from '../instances.json'
import {getSDKVersion} from './helpers'
import {hasFeature} from '../features'

const isEmpty = value => {
  if (!value) {
    return true
  }

  if (typeof value === 'string') {
    return value.trim().length === 0
  }

  return true
}

/**
 * Settings that the app stores locally.
 */
const Settings = {
  configuration: {},
  sdkFeatures: {},
  load: () => {
    let json = {}

    try {
      json = JSON.parse(localStorage.getItem(CONFIGURATION_KEY))
    } catch (e) {}

    if (json !== null) {
      Settings.configuration = json
    }

    let featureJson = {}

    try {
      featureJson = JSON.parse(localStorage.getItem(FEATURE_CONFIGURATION_KEY))
    } catch (e) {}

    if (featureJson !== null) {
      Settings.sdkFeatures = featureJson
    }

    if (!Settings.configuration.embedMode) {
      // Apply default for embed mode if not already specified.
      Settings.configuration.embedMode = 'launcher'
    }

    if (Settings.sdkFeatures.cardListToastEnabled === undefined) {
      // Card list toasts default to being enabled.
      Settings.sdkFeatures.cardListToastEnabled = true
    }

    if (Settings.sdkFeatures.cardListHeader === undefined) {
      Settings.sdkFeatures.cardListHeader = true
    }

    if (Settings.sdkFeatures.runtimeVariableAnalytics === undefined) {
      Settings.sdkFeatures.runtimeVariableAnalytics =
        instance.identifier === 'beta' ? false : true
    }

    if (Settings.sdkFeatures.horizontalContainerConfig === undefined) {
      Settings.sdkFeatures.horizontalContainerConfig = {
        cardWidth: 350,
        emptyStyle: 'standard',
        headerAlignment: 'center',
        scrollMode: 'snap',
        lastCardAlignment: 'left'
      }
    }

    if (Settings.sdkFeatures.toastConfig === undefined) {
      Settings.sdkFeatures.toastConfig = {}
    }

    if (!hasFeature('sdkEventObserver', getSDKVersion())) {
      delete Settings.sdkFeatures.observeSDKEvents
    } else if (Settings.sdkFeatures.observeSDKEvents === undefined) {
      Settings.sdkFeatures.observeSDKEvents = false
    }

    if (!hasFeature('fetchIconsRemotely', getSDKVersion())) {
      delete Settings.sdkFeatures.fetchIconsRemotely
    } else if (Settings.sdkFeatures.fetchIconsRemotely === undefined) {
      Settings.sdkFeatures.fetchIconsRemotely = false
    }
  },
  save: () => {
    if (!Settings.configuration) {
      return
    }

    localStorage.setItem(
      CONFIGURATION_KEY,
      JSON.stringify(Settings.configuration)
    )

    if (!Settings.sdkFeatures) {
      return
    }

    localStorage.setItem(
      FEATURE_CONFIGURATION_KEY,
      JSON.stringify(Settings.sdkFeatures)
    )
  },
  reset: () => {
    Settings.configuration.organisationId = null
    Settings.configuration.siteId = null
    Settings.configuration.streamContainerId = null
    Settings.configuration.authSecret = null

    Settings.save()
  },
  hasCompleteConfiguration: () => {
    const completeConfig = [
      !isEmpty(Settings.configuration.organisationId),
      !isEmpty(Settings.configuration.siteId),
      !isEmpty(Settings.configuration.streamContainerId),
      ...(Settings.configuration.customTokenUrl
        ? [!isEmpty(Settings.configuration.customTokenUrl)]
        : [!isEmpty(Settings.configuration.authSecret)])
    ]
    return !completeConfig.includes(false)
  },
  selectedEnvironmentId: () => {
    if (instance.identifier === 'beta') {
      // For the beta app, always use the first environment (production).
      return environmentData.environments[0].identifier
    } else {
      // For the shell app, use the environment switcher.
      return (
        Settings.configuration.environmentId ||
        environmentData.environments[environmentData.environments.length - 1]
          .identifier
      )
    }
  },
  currentEnvironment: () => {
    const envId = Settings.selectedEnvironmentId()
    const envs = environmentData.environments.filter(env => {
      return env.identifier === envId
    })
    return envs.length ? envs[0] : null
  },
  currentApiUrl: () => {
    const orgId = Settings.configuration.organisationId || ''
    const envUrl = Settings.currentEnvironment().url

    if (Settings.currentEnvironment().identifier === 'local') {
      // local urls are not prefixed with org id
      return envUrl
    }

    const orgIdPrefix = orgId.trim().length == 0 ? '' : `${orgId}.`
    return `https://${orgIdPrefix}${envUrl}`
  },
  currentTestHostUrl: () => {
    if (Settings.currentEnvironment().identifier === 'local') {
      // Test host is only used for local development
      const orgId = Settings.configuration.organisationId || ''
      return `${orgId}.client-api.atomic.io`
    }

    return undefined
  },
  sdkUrl: version => {
    return `${instance.sdkSourceUrl}/${version || instance.sdkVersion}/sdk${
      Settings.sdkFeatures.fetchIconsRemotely ? '-cdn-icons' : ''
    }.js`
  }
}

const parseMagicLink = url => {
  if (!url.search || url.search.length === 0) {
    return null
  }

  const params = new URLSearchParams(url.search)

  const link = {
    organisationId: params.get('orgId'),
    siteId: params.get('siteId'),
    streamContainerId: params.get('containerId'),
    authSecret: params.get('authSecret'),
    apiEnvironment: params.get('apiEnv')
  }

  if (
    instance.identifier !== 'alpha' &&
    [
      link.organisationId,
      link.siteId,
      link.streamContainerId,
      link.authSecret
    ].some(val => isEmpty(val))
  ) {
    // enforce all magic link parameters for shell & demo apps
    return null
  }

  if (params.get('customTokenUrl')) {
    link.customTokenUrl = params.get('customTokenUrl')
  }

  return link
}

export {Settings, parseMagicLink, isEmpty}
