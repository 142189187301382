// Copyright © 2020 Atomic.io Limited. All rights reserved.
import {Settings} from '../util/settings'

let cachedToken = null

/**
 * Number of seconds before the token expiry (`exp`) when the JWT
 * should be considered expired.
 */
const EXPIRY_THRESHOLD = 300

/**
 * Verify that the provided token is non-empty, valid and has not yet expired.
 */
const isTokenValid = token => {
  if (!token) {
    // No token provided.
    return false
  }

  const tokenParts = token.split('.')

  if (tokenParts.length !== 3) {
    // Must have 3 components.
    console.error('JWT is malformed.')
    return false
  }

  let jwt = null

  try {
    jwt = JSON.parse(atob(tokenParts[1]))
  } catch (e) {
    console.error('Failed to parse JWT.')
    return false
  }

  if (!jwt || !jwt.exp) {
    // No valid JWT or `exp` field
    return false
  }

  const now = new Date().getTime()
  const expiry = (jwt.exp - EXPIRY_THRESHOLD) * 1000

  return expiry - now > 0
}

const getAuthToken = async () => {
  if (isTokenValid(cachedToken)) {
    // Check the token is valid first.
    return cachedToken
  }

  const authSecret = Settings.configuration.authSecret
  const baseUrl = Settings.currentApiUrl()
  const siteId = Settings.configuration.siteId
  const customTokenUrl = Settings.configuration.customTokenUrl
  const tokenUrl = customTokenUrl
    ? customTokenUrl
    : `${baseUrl}/${siteId}/token`

  if (!customTokenUrl && (!authSecret || !baseUrl || !siteId)) {
    return null
  }

  const headers = new Headers()
  if (!customTokenUrl) {
    headers.append('x-atomic-auth-secret', authSecret)
  }

  if (Settings.currentTestHostUrl()) {
    headers.append('x-atomic-test-host', Settings.currentTestHostUrl())
  }

  const resp = await fetch(tokenUrl, {
    method: 'get',
    headers
  })
  const json = await resp.json()

  if (json.errors) {
    const error = json.errors[0]
    console.error(
      'Failed to fetch an authentication token for the Atomic SDK.',
      error
    )
  } else if (json.data.token) {
    sessionStorage.setItem('apiKey', json.data.apiKey)

    cachedToken = json.data.token
    return cachedToken
  }

  return null
}

const clearCachedToken = () => {
  cachedToken = null
}

const getCachedToken = () => {
  return cachedToken
}

export {getAuthToken, clearCachedToken, getCachedToken}
