// Copyright © 2019 Atomic.io Limited. All rights reserved.

/**
 * Routes known to the app.
 */
const Routes = {
  settings: 'settings',
  configure: 'configure',
  features: 'features'
}

export {Routes}
