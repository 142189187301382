// Copyright © 2019 Atomic.io Limited. All rights reserved.
import React, {useState} from 'react'
import {Settings, isEmpty} from '../util/settings'
import {SettingsIcon} from '../components/icons'
import {clearCachedToken} from '../api/token'
import instance from '../instances.json'

const SettingsOption = {
  streamContainerId: 'streamContainerId',
  orgId: 'orgId',
  siteId: 'siteId',
  authSecret: 'authSecret',
  backgroundUrl: 'backgroundUrl',
  customTokenUrl: 'customTokenUrl'
}

const InputField = ({placeholder, value, onChange, identifier, fieldClass}) => {
  return (
    <label className="input-field" htmlFor={identifier}>
      <input
        placeholder=" "
        type="text"
        className={fieldClass}
        id={identifier}
        value={value}
        onChange={onChange}
      />
      <div className="fake-label">{placeholder}</div>
    </label>
  )
}

const isValid = (orgId, siteId, containerId, authSecret, customTokenUrl) => {
  if (
    [orgId, siteId, containerId, ...(customTokenUrl ? [] : [authSecret])].some(
      val => isEmpty(val)
    )
  ) {
    return false
  }

  return true
}

const titleForOption = option => {
  switch (option) {
    case SettingsOption.streamContainerId:
      return 'Container ID'
    case SettingsOption.orgId:
      return 'Organisation ID'
    case SettingsOption.siteId:
      return 'Environment ID'
    case SettingsOption.authSecret:
      return 'Auth secret'
    case SettingsOption.backgroundUrl:
      return 'Background URL'
    case SettingsOption.customTokenUrl:
      return 'Custom token URL'
  }
}

const cssClassesForOption = option => {
  switch (option) {
    case SettingsOption.orgId:
    case SettingsOption.siteId:
    case SettingsOption.authSecret:
      return 'monospace'
    default:
      return ''
  }
}

/**
 * Screen used to configure the app.
 */
const SettingsInput = ({onCancel, onSettingsChanged}) => {
  const configuration = Settings.configuration
  const initialValues = {
    orgId: configuration.organisationId || '',
    siteId: configuration.siteId || '',
    streamContainerId: configuration.streamContainerId || '',
    authSecret: configuration.authSecret || '',
    backgroundUrl: configuration.backgroundUrl || '',
    customTokenUrl: configuration.customTokenUrl || ''
  }

  const [inputVals, setInputVals] = useState({...initialValues})

  const getterForOption = option => {
    switch (option) {
      case SettingsOption.orgId:
        return inputVals.orgId
      case SettingsOption.siteId:
        return inputVals.siteId
      case SettingsOption.streamContainerId:
        return inputVals.streamContainerId
      case SettingsOption.authSecret:
        return inputVals.authSecret
      case SettingsOption.backgroundUrl:
        return inputVals.backgroundUrl
      case SettingsOption.customTokenUrl:
        return inputVals.customTokenUrl
    }
  }

  const setterForOption = option => {
    switch (option) {
      case SettingsOption.orgId:
        return event => setInputVals({...inputVals, orgId: event.target.value})
      case SettingsOption.siteId:
        return event => setInputVals({...inputVals, siteId: event.target.value})
      case SettingsOption.authSecret:
        return event =>
          setInputVals({...inputVals, authSecret: event.target.value})
      case SettingsOption.streamContainerId:
        return event =>
          setInputVals({...inputVals, streamContainerId: event.target.value})
      case SettingsOption.backgroundUrl:
        return event =>
          setInputVals({...inputVals, backgroundUrl: event.target.value})
      case SettingsOption.customTokenUrl:
        return event =>
          setInputVals({...inputVals, customTokenUrl: event.target.value})
    }
  }

  const options = [
    ...(instance.authSecret
      ? [
          SettingsOption.orgId,
          SettingsOption.siteId,
          SettingsOption.streamContainerId,
          SettingsOption.authSecret
        ]
      : []),
    SettingsOption.backgroundUrl,
    ...(instance.customTokenUrl ? [SettingsOption.customTokenUrl] : [])
  ]

  return (
    <div className="settings-wrapper">
      <div className="layout-margin"></div>
      <div className="settings-inner">
        <SettingsIcon />

        <h1>Edit settings</h1>
        <p className="settings-subtitle">
          You can find these details in the Workbench
        </p>

        <div className="settings-form">
          {options.map(value => {
            return (
              <InputField
                placeholder={titleForOption(value)}
                value={getterForOption(value)}
                onChange={setterForOption(value)}
                key={value}
                identifier={value}
                fieldClass={cssClassesForOption(value)}
              />
            )
          })}
          <button
            className="button-dark"
            data-testId="save-edit-settings"
            onClick={() => {
              if (
                !isValid(
                  inputVals.orgId,
                  inputVals.siteId,
                  inputVals.streamContainerId,
                  inputVals.authSecret,
                  inputVals.customTokenUrl
                )
              ) {
                return
              }

              Settings.configuration.organisationId = inputVals.orgId
              Settings.configuration.siteId = inputVals.siteId
              Settings.configuration.streamContainerId =
                inputVals.streamContainerId
              Settings.configuration.authSecret = inputVals.authSecret
              Settings.configuration.backgroundUrl = inputVals.backgroundUrl
              Settings.configuration.customTokenUrl = inputVals.customTokenUrl
              Settings.save()

              if (
                initialValues.orgId !== inputVals.orgId ||
                initialValues.siteId !== inputVals.siteId ||
                initialValues.streamContainerId !==
                  inputVals.streamContainerId ||
                initialValues.authSecret !== inputVals.authSecret ||
                initialValues.backgroundUrl !== inputVals.backgroundUrl ||
                initialValues.customTokenUrl !== inputVals.customTokenUrl
              ) {
                // Clear the cached token if settings have changed.
                clearCachedToken()

                onSettingsChanged()
              }
            }}
          >
            Save
          </button>

          <button
            onClick={onCancel}
            className="button-light"
            data-testId="cancel-edit-settings"
          >
            Cancel
          </button>
        </div>
      </div>
      <div className="layout-margin"></div>
    </div>
  )
}

export {SettingsInput, SettingsOption}
