// Copyright © 2020 Atomic.io Limited. All rights reserved.
import React from 'react'
import {FullLogo, MagicIcon} from '../components/icons'
import {isMobileDevice, getPlatform} from '../util/helpers'
import {MobileLanding, SKIP_MOBILE_REMINDER} from './mobile-landing'
import instanceConfig from '../instances.json'

const platform = getPlatform()
const skipMobilePage = sessionStorage.getItem(SKIP_MOBILE_REMINDER) === 'true'

const Landing = ({onManualEntry, onMagicLink}) => {
  if (
    instanceConfig.mobileLandingPage &&
    !skipMobilePage &&
    isMobileDevice() &&
    platform !== 'Unknown'
  ) {
    return <MobileLanding />
  }

  return (
    <div className="landing">
      <div className="layout-margin">
        <FullLogo />
      </div>
      <div className="landing-content">
        <MagicIcon />

        <h1>Log in with your magic link</h1>

        <p>
          To connect the Atomic app to your test user account, you’ll need a
          magic link. Alternatively, if you know your details already, you can
          enter them manually below.
        </p>

        <button
          className="button button-dark"
          onClick={onMagicLink}
          data-testId="magic-auth-link"
        >
          Get magic auth link
        </button>
        <button className="button button-light" onClick={onManualEntry}>
          Enter manually
        </button>
      </div>
      <div className="layout-margin"></div>
    </div>
  )
}

export {Landing}
