import React from 'react'
import {getPlatform} from '../util/helpers'
import AtomicLogo from '../assets/atomic-logo-on-dark.svg'

const platform = getPlatform()
export const SKIP_MOBILE_REMINDER = 'skip-mobile-landing'

export const MobileLanding = () => {
  const skipMobilePage = () => {
    sessionStorage.setItem(SKIP_MOBILE_REMINDER, 'true')
    location.reload()
  }
  const openAppStore = () => {
    const url =
      platform === 'iOS'
        ? 'https://apps.apple.com/app/atomic-connect/id1475838567'
        : 'https://play.google.com/store/apps/details?id=io.atomic.beta'
    window.open(url, '_blank')
  }
  return (
    <div className="mobile-landing">
      <img className="mobile-logo" src={AtomicLogo} />
      <div className="card">
        <h1 className="mobile-heading">
          It looks like you don&lsquo;t have the Atomic Connect app on this
          device yet?
        </h1>
        <ul>
          <li>Receive and interact with cards sent from Atomic</li>
          <li>
            Receive native push notifications as part of testing your card
            configuration
          </li>
          <li>Test your custom Themes on a real device</li>
          <li>Demo your Atomic cards</li>
        </ul>
        <button className="button-positive" onClick={openAppStore}>
          Install Atomic Connect for {platform}
        </button>
        <button className="button-negative" onClick={skipMobilePage}>
          Remind me next time
        </button>
      </div>
    </div>
  )
}
