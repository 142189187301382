const DB_NAME = 'AtomicConnect'
const EVENT_STORE = 'sdk_events'

/**
 * Thin wrapper around IndexedDB that allows it to be accessed via Promises.
 */
export const db = () => {
  return new Promise((resolve, reject) => {
    const openRequest = window.indexedDB.open(DB_NAME, 1)
    openRequest.onerror = err => {
      reject(err)
    }
    openRequest.onsuccess = () => {
      resolve(openRequest.result)
    }
    openRequest.onupgradeneeded = () => {
      // Create the data store if it does not already exist.
      const database = openRequest.result

      if (openRequest.transaction) {
        openRequest.transaction.oncomplete = () => {
          resolve(openRequest.result)
        }
        openRequest.transaction.onerror = err => {
          reject(err)
        }
        openRequest.transaction.onabort = err => {
          reject(err)
        }
      }

      const store = database.createObjectStore(EVENT_STORE)
      store.createIndex('event_name', 'event_name', {
        unique: false
      })
    }
  })
}

/**
 * Stores an SDK event keyed by the event name
 */
export const storeSDKEvent = async (eventName, sdkEvent) => {
  const database = await db()
  return new Promise((resolve, reject) => {
    const txn = database.transaction(EVENT_STORE, 'readwrite')
    const getOperation = txn.objectStore(EVENT_STORE).get(eventName)
    getOperation.onsuccess = () => {
      const events = getOperation.result || []
      events.push(sdkEvent)
      const putOperation = txn.objectStore(EVENT_STORE).put(events, eventName)

      putOperation.onsuccess = () => {
        database.close()
        const result = putOperation.result
        resolve({
          eventName,
          events: result
        })
      }
    }
    getOperation.onerror = err => {
      console.error('error retrieving sdk events', err)
      const putOperation = txn
        .objectStore(EVENT_STORE)
        .put([sdkEvent], eventName)

      putOperation.onsuccess = () => {
        database.close()
        const result = putOperation.result
        resolve({
          eventName,
          events: result
        })
      }
      putOperation.onerror = err => {
        database.close()
        reject(err)
      }
    }
  })
}

export const clearSDKEvents = async () => {
  const database = await db()
  return new Promise((resolve, reject) => {
    const txn = database.transaction(EVENT_STORE, 'readwrite')
    const clearOperation = txn.objectStore(EVENT_STORE).clear()
    clearOperation.onsuccess = () => {
      database.close()
      resolve()
    }
    clearOperation.onerror = err => {
      database.close()
      reject(err)
    }
  })
}
