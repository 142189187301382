import {clearCachedToken, getAuthToken, getCachedToken} from '../api/token'
import instanceConfig from '../instances.json'
import ArrowUp from '../assets/angle-up-solid.svg'
import ArrowDown from '../assets/angle-down-solid.svg'
import instance from '../instances.json'
import {storeSDKEvent} from '../util/database'
import {hasFeature} from '../features'
import {getSDKVersion} from '../util/helpers'

// Copyright © 2019 Atomic.io Limited. All rights reserved.
const createStreamContainer = (
  apiHost,
  streamContainerId,
  onCardCountChanged,
  embedMode,
  apiKey,
  siteId,
  sdkFeatures,
  testHost,
  cardFeedHandler
) => {
  if (window.AtomicSDK.singleCard) {
    // Disable WebSockets if specified in config
    window.AtomicSDK.internalConfig = {
      ...window.AtomicSDK.internalConfig,
      webSocketDisabled: sdkFeatures.webSocketDisabled ? true : false
    }

    // Newer SDK versions take the API host as part of initialise.
    window.AtomicSDK.initialise(apiHost, apiKey, siteId)
    // Set session delegate
    window.AtomicSDK.setSessionDelegate(async () => await getAuthToken())
  } else {
    // Older SDK versions have API host in the config object.
    window.AtomicSDK.initialise(apiKey, siteId)
  }

  if (window.AtomicSDK.setTestHostHeader) {
    // SDK version 0.19.0+ supports setting of the test host header for local development.
    window.AtomicSDK.setTestHostHeader(testHost)
  }

  if (hasFeature('sdkEventObserver', getSDKVersion())) {
    if (sdkFeatures.observeSDKEvents) {
      window.AtomicSDK.observeSDKEvents(async event => {
        if (window.AtomicSDK.userMetricsTriggerEvents.has(event.eventName)) {
          window.AtomicSDK.requestUserMetrics().then(metrics => {
            const containerMetrics =
              metrics.metricsByContainer[streamContainerId]
            console.log('Container metrics triggered: ', containerMetrics)
          })
        }
        instanceConfig.retainSDKEvents &&
          (await storeSDKEvent(event.eventName, event))
      })
    }
  }

  if (sdkFeatures.simulateTokenError) {
    clearCachedToken()

    window.AtomicSDK.setSessionDelegate(async () => {
      if (!getCachedToken()) {
        // Cache auth token but return an error token this time.
        setTimeout(getAuthToken, 1000)
        return Promise.resolve('invalid.auth.token')
      }
      return await getAuthToken()
    })
  }
  const launcherButtonConfig = JSON.parse(
    sdkFeatures.customiseLauncherButton || '{}'
  )

  const config = {
    apiHost,
    streamContainerId,
    onCardCountChanged,
    cardListRefreshInterval: 5000,
    enabledUiElements: {
      cardListToast: sdkFeatures.cardListToastEnabled,
      toastConfig: {
        ...(sdkFeatures.toastConfig
          ? {
              timeout: sdkFeatures.toastConfig.timeout,
              toastMessages: {
                submittedCard: sdkFeatures.toastConfig.submittedCard,
                dismissedCard: sdkFeatures.toastConfig.dismissedCard,
                snoozedCard: sdkFeatures.toastConfig.snoozedCard,
                feedbackReceived: sdkFeatures.toastConfig.feedbackReceived
              }
            }
          : {})
      },
      cardListHeader: sdkFeatures.cardListHeader,
      customContainerHeader: sdkFeatures.customContainerHeader && {
        scrollHeader: true,
        headerElement: `
        <div style="padding: 10px;background-color: dodgerBlue;border-radius: 5px;">
          <h1 style="color: white">Custom Header</h1>
        </div>
        `
      },
      launcherButton: {
        ...launcherButtonConfig,
        openIconSrc:
          launcherButtonConfig.openIconSrc === 'custom' ? ArrowUp : '',
        closeIconSrc:
          launcherButtonConfig.closeIconSrc === 'custom' ? ArrowDown : ''
      }
    },
    customStrings: {
      cardListTitle: 'Things to do'
    },
    interfaceStyle: sdkFeatures.interfaceStyle || 'automatic',
    onRuntimeVariablesRequested: (cards, cb) => {
      const date = new Date()

      cards.forEach(card => {
        card.runtimeVariables.set('timestamp', date.getSeconds().toString())
        card.runtimeVariables.set('dateShort', date.toDateString())
        card.runtimeVariables.set('dateLong', date.toString())
      })

      cb(cards)
    },
    onLinkButtonPressed: payload => {
      alert(
        `A link button with a custom payload, defined in the Workbench, was tapped:\n\n${JSON.stringify(
          payload
        )}`
      )
    },
    onSubmitButtonPressed: payload => {
      alert(
        `A submit button with a custom payload, defined in the Workbench, was tapped:\n\n${JSON.stringify(
          payload
        )}`
      )
    },
    onSizeChanged: (width, height) => {
      console.log('Single card view changed size to ', width, height)
    },
    onCardEvent: event => {
      console.log(`[Card event] ${event.type}`)
    },
    features: {
      cardMinimumHeight: sdkFeatures.cardMinimumHeight,
      horizontalContainerConfig: {...sdkFeatures.horizontalContainerConfig},
      runtimeVariableAnalytics: sdkFeatures.runtimeVariableAnalytics
    }
  }

  if (embedMode === 'standalone') {
    config.features.horizontalContainerConfig.enabled = false

    return window.AtomicSDK.embed(
      document.getElementById('embed'),
      config,
      sdkFeatures.autoresizeStandaloneFrame
    )
  } else if (embedMode === 'standalone-horizontal') {
    config.features.horizontalContainerConfig.enabled = true

    return window.AtomicSDK.embed(
      document.getElementById('horizontal-embed'),
      config,
      instance.sdkType === 'staging'
        ? sdkFeatures.autoresizeStandaloneFrame
        : true
    )
  } else if (embedMode === 'single-card' && window.AtomicSDK.singleCard) {
    // Current SDK version supports single card mode.
    return window.AtomicSDK.singleCard(document.getElementById('embed'), config)
  } else if (
    embedMode === 'headless' &&
    hasFeature('headlessStreamContainer', getSDKVersion())
  ) {
    return window.AtomicSDK.observableStreamContainer({
      ...config,
      cardFeedHandler
    })
  } else {
    config.onLauncherToggled = isOpen => {
      instance.identifier !== 'beta' &&
        console.log('Launcher is open: ', isOpen)
    }
    return window.AtomicSDK.launch(config)
  }
}

export {createStreamContainer}
