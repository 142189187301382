import instance from './instances.json'

/**
 * These are the currently supported versions of the Web SDK
 */
const currentBetaReleases = []
const currentStableReleases = {
  '24.2.x': ['24.2.0', '24.2.1'],
  '24.1.x': ['24.1.0', '24.1.1', '24.1.2'],
  '23.4.x': ['23.4.0', '23.4.1', '23.4.2', '23.4.3', '23.4.4', '23.4.5'],
  '23.3.x': [
    '23.3.0',
    '23.3.1',
    '23.3.2',
    '23.3.3',
    '23.3.4',
    '23.3.5',
    '23.3.6',
    '23.3.7'
  ],
  '1.6.x': ['1.6.1', '1.6.2', '1.6.3', '1.6.4', '1.6.5']
}

/**
 * These are the versions of the SDK that will be available in the version selector in the customer facing Atomic Connect app
 */
export const ConnectAppSDKVersions = [
  {identifier: '24.2.1', name: 'latest stable (24.2.1)'},
  {identifier: '24.1.2', name: 'stable release (24.1.2)'},
  {identifier: '23.4.5', name: 'stable release (23.4.5)'},
  {identifier: '23.3.7', name: 'stable release (23.3.7)'},
  {identifier: '1.6.5', name: 'stable release (1.6.5)'}
]

/**
 * These are the versions of the SDK that will be available in the version selector in the internal Shell app
 */
export const ShellAppSDKVersions = [...ConnectAppSDKVersions]

/**
 * These are the versions of the SDK that will be available in the version selector in the internal Atomic Alpha app
 */
export const AlphaAppSDKVersions = [...ShellAppSDKVersions]

const featureFlags = {
  sdkEventObserver: {
    develop: new Set([
      ...currentStableReleases['24.2.x'],
      ...currentStableReleases['24.1.x'],
      ...currentStableReleases['23.4.x'],
      ...currentStableReleases['23.3.x'],
      ...currentBetaReleases
    ]),
    staging: new Set([
      ...currentStableReleases['24.2.x'],
      ...currentStableReleases['24.1.x'],
      ...currentStableReleases['23.4.x'],
      ...currentStableReleases['23.3.x'],
      ...currentBetaReleases
    ]),
    release: new Set([...currentBetaReleases])
  },
  headlessStreamContainer: {
    develop: new Set([
      ...currentStableReleases['24.2.x'],
      ...currentStableReleases['24.1.x'],
      ...currentStableReleases['23.4.x'],
      ...currentBetaReleases
    ]),
    staging: new Set([
      ...currentStableReleases['24.2.x'],
      ...currentStableReleases['24.1.x'],
      ...currentStableReleases['23.4.x'],
      ...currentBetaReleases
    ]),
    release: new Set([])
  },
  fetchIconsRemotely: {
    develop: new Set(['24.2.1', ...currentBetaReleases]),
    staging: new Set(['24.2.1', ...currentBetaReleases]),
    release: new Set([])
  }
}

/**
 * @param {string} featureKey
 * @param {string} versionString
 * @returns A boolean indicating whether the feature is enabled for the given version.
 */
export const hasFeature = (featureKey, versionString) => {
  if (typeof featureKey !== 'string' && typeof versionString !== 'string') {
    return false
  }
  if (!featureFlags[featureKey]) {
    return false
  }
  if (!featureFlags[featureKey][instance.sdkType]) {
    return false
  }
  if (!featureFlags[featureKey][instance.sdkType].has(versionString)) {
    return false
  }
  return true
}

/**
 * @param {string} versionString
 * @returns An array of feature keys that are excluded for the given version.
 */
export const getExcludedFeaturesList = versionString => {
  const excludedFeatures = []
  if (typeof versionString !== 'string') {
    return excludedFeatures
  }
  for (const featureKey in featureFlags) {
    if (!hasFeature(featureKey, versionString)) {
      excludedFeatures.push(featureKey)
    }
  }

  return excludedFeatures
}
