// Copyright © 2019 Atomic.io Limited. All rights reserved.
import React, {useState, useEffect} from 'react'
import {AtomicLogo, Logo, SettingsTrigger} from '../components/icons'
import {DropdownMenu} from '../components/dropdown'
import {Settings} from '../util/settings'

const Nav = ({
  changeRoute,
  onSettingChanged,
  onLogout,
  onRefreshRuntimeVariables,
  onConfigureSDKFeatures
}) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <nav className={Settings.configuration.backgroundUrl && 'nav-hidden'}>
      {!Settings.configuration.backgroundUrl && (
        <AtomicLogo
          href={Settings.currentEnvironment().workbenchUrl}
          target="_blank"
          title="Open Workbench"
        >
          <Logo width={'22'} height={'22'} style={{marginTop: '-2px'}} />
        </AtomicLogo>
      )}

      {isOpen && (
        <DropdownMenu
          changeRoute={changeRoute}
          onSettingChanged={onSettingChanged}
          onLogout={onLogout}
          onClose={e => {
            setIsOpen(false)
            e.stopPropagation()
          }}
          onRefreshRuntimeVariables={onRefreshRuntimeVariables}
          onConfigureSDKFeatures={onConfigureSDKFeatures}
        />
      )}

      <SettingsTrigger
        className={
          Settings.configuration.backgroundUrl && 'settings-trigger-hidden'
        }
        onClick={() => setIsOpen(!isOpen)}
        isOpen={isOpen}
      ></SettingsTrigger>
    </nav>
  )
}

/**
 * The landing screen of the sample app.
 */
const Dashboard = ({
  changeRoute,
  cardCount,
  onLaunch,
  onSettingChanged,
  onLogout,
  onRefreshRuntimeVariables,
  onConfigureSDKFeatures
}) => {
  useEffect(onLaunch, [])
  const launcherDisabled = Settings.sdkFeatures.customiseLauncherButton
    ? JSON.parse(Settings.sdkFeatures.customiseLauncherButton).disabled
    : false

  return (
    <>
      <Nav
        changeRoute={changeRoute}
        onLogout={onLogout}
        onSettingChanged={onSettingChanged}
        onRefreshRuntimeVariables={onRefreshRuntimeVariables}
        onConfigureSDKFeatures={onConfigureSDKFeatures}
      />
      {Settings.configuration.backgroundUrl && (
        <img
          className="background-image"
          src={Settings.configuration.backgroundUrl}
        />
      )}
      {!launcherDisabled && (
        <div id="badge" style={{display: cardCount > 0 ? 'block' : 'none'}}>
          {cardCount}
        </div>
      )}
    </>
  )
}

export {Dashboard}
